type LoadingType = "lazy" | "eager";

interface LogoProps {
    height?: number;
    loading?: LoadingType;
}
export default function Logo({ height = 25, loading = "lazy" }: LogoProps) {
    return (
        <img
            src="https://cdn.interlacekit.com/logo-type.webp"
            height={height}
            width="auto"
            loading={loading}
            alt="InterlaceKit logo"
        />
    );
}
